<template>
  <div class="actions">
    <b-button
      variant="info"
      size="sm"
      class="px-3"
      @click="joinCampaign"
      v-if="
        rowData.agentCanLogoutAndRejoin &&
          ['active', 'created'].includes(rowData.status) &&
          rowData.agentStatus === 'left'
      "
      :disabled="isJoining"
    >
      <i class="fa fa-user-plus" aria-hidden="true" v-if="!isJoining"></i>
      <span class="mx-1">
        {{ $t('auto-dialer.list.table.actionsButtons.join') }}
      </span>
      <half-circle-spinner
        :animation-duration="1500"
        :size="20"
        v-if="isJoining"
      />
    </b-button>

    <b-button
      variant="success"
      size="sm"
      class="px-3"
      @click="leaveCampaign"
      v-if="
        rowData.agentCanLogoutAndRejoin &&
          ['active'].includes(rowData.status) &&
          rowData.agentStatus === 'joined'
      "
      :disabled="isLeaving"
    >
      <i class="fa fa-user-times" aria-hidden="true" v-if="!isLeaving"></i>
      <span class="mx-1">
        {{ $t('auto-dialer.list.table.actionsButtons.leave') }}
      </span>
      <half-circle-spinner
        :animation-duration="1500"
        :size="20"
        v-if="isLeaving"
      />
    </b-button>

    <router-link
      :to="`/auto-dialer/${rowData.id}/cdrs`"
      v-if="
        ['completed', 'finished', 'paused', 'active', 'in-progress'].includes(
          rowData.status
        )
      "
    >
      <b-button variant="info" size="sm" class="px-3">
        <i class="fa fa-list" aria-hidden="true"></i>
        <span class="mx-1">
          {{ $t('auto-dialer.list.table.actionsButtons.cdrs') }}
        </span>
      </b-button>
    </router-link>

    <!-- <b-button class="px-3" variant="danger" size="sm" @click="deleteCampaign">
      <i class="fa fa-trash" aria-hidden="true"></i>
    </b-button> -->
  </div>
</template>

<script>
import AutoDialerService from '../../../../services/autoDialer.service'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
import Swal from 'sweetalert2'

export default {
  components: {
    HalfCircleSpinner
  },
  props: {
    rowData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isJoining: false,
      isLeaving: false
    }
  },
  computed: {},
  methods: {
    async joinCampaign() {
      this.isJoining = true
      try {
        await AutoDialerService.joinCampaign(this.rowData.id)
        this.$emit('refetch')
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.response.data.message
        })
      } finally {
        this.isJoining = false
      }
    },
    async leaveCampaign() {
      this.isLeaving = true
      try {
        await AutoDialerService.leaveCampaign(this.rowData.id)
        this.$emit('refetch')
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.response.data.message
        })
      } finally {
        this.isLeaving = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
</style>
