var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"actions"},[(
      _vm.rowData.agentCanLogoutAndRejoin &&
        ['active', 'created'].includes(_vm.rowData.status) &&
        _vm.rowData.agentStatus === 'left'
    )?_c('b-button',{staticClass:"px-3",attrs:{"variant":"info","size":"sm","disabled":_vm.isJoining},on:{"click":_vm.joinCampaign}},[(!_vm.isJoining)?_c('i',{staticClass:"fa fa-user-plus",attrs:{"aria-hidden":"true"}}):_vm._e(),_c('span',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(_vm.$t('auto-dialer.list.table.actionsButtons.join'))+" ")]),(_vm.isJoining)?_c('half-circle-spinner',{attrs:{"animation-duration":1500,"size":20}}):_vm._e()],1):_vm._e(),(
      _vm.rowData.agentCanLogoutAndRejoin &&
        ['active'].includes(_vm.rowData.status) &&
        _vm.rowData.agentStatus === 'joined'
    )?_c('b-button',{staticClass:"px-3",attrs:{"variant":"success","size":"sm","disabled":_vm.isLeaving},on:{"click":_vm.leaveCampaign}},[(!_vm.isLeaving)?_c('i',{staticClass:"fa fa-user-times",attrs:{"aria-hidden":"true"}}):_vm._e(),_c('span',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(_vm.$t('auto-dialer.list.table.actionsButtons.leave'))+" ")]),(_vm.isLeaving)?_c('half-circle-spinner',{attrs:{"animation-duration":1500,"size":20}}):_vm._e()],1):_vm._e(),(
      ['completed', 'finished', 'paused', 'active', 'in-progress'].includes(
        _vm.rowData.status
      )
    )?_c('router-link',{attrs:{"to":`/auto-dialer/${_vm.rowData.id}/cdrs`}},[_c('b-button',{staticClass:"px-3",attrs:{"variant":"info","size":"sm"}},[_c('i',{staticClass:"fa fa-list",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(_vm.$t('auto-dialer.list.table.actionsButtons.cdrs'))+" ")])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }